import { DynamicImport } from './models/dynamic-import.model';

export function replaceImport(functionString: string, { importName, varName }: DynamicImport) {
  return functionString.replace(
    new RegExp(`import\\s*([\\s{][^;]*[\\s}])\\s*from\\s*(["'])${importName}\\2`, 'g'),
    `const $1 = ${varName}`,
  );
}

export function replaceVariableExports(functionString: string) {
  const exportReplaced = functionString.replace(/export\s+(const|var)/g, `$1`).replace(/export(?!\s+default)/g, 'export default');

  const enr = /export\s+(?:const|var)\s+(.+?)\s?(?:=|;)/g;

  const exportNames: string[] = [];
  let match: RegExpExecArray | null;

  while ((match = enr.exec(functionString))) {
    exportNames.push(match[1]);
  }

  const defaultExport = exportNames.length ? `;export default {${exportNames.join(',')}}` : '';

  return `${exportReplaced}${defaultExport}`;
}

export function transformDefaultExportToReturn(src: string) {
  return src.replace(/(\n|;)\s*export\s+default\s*(.*)/, '$1return $2');
}

export function loadDynamicJS(src: string, variables: Record<string, unknown>) {
  const varEntries = Object.entries(variables);
  return Function(...varEntries.map(([k]) => k), `"use strict";${src}`)(...varEntries.map(([, v]) => v));
}
